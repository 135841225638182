.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner {
  background-color: #007acc; /* Set your desired background color */
  color: #fff; /* Set your desired text color */
  padding: 20px;
}

.content {
  color: black;
}

/* Navigation Styling */
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #007acc;
  color: #fff;
  padding: 20px;
}

h1 {
  font-size: 24px;
}

ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

li {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #fff;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #47a83f;
  color: #fff;
  padding: 10px;
  z-index: 100;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 88%
}

.navbar-logo {
  display: flex;
  align-items: center; /* Center the logo vertically */
  text-decoration: none;
}

.logo-image {
  max-width: 150px; /* Set a maximum width for the logo */
  height: auto; /* Maintain aspect ratio */
  /* Add any other styles as per your design requirements */
}

.menu-icon {
  display: none; /* Hide the hamburger icon by default on larger screens */
  cursor: pointer;
}

.nav-menu {
  display: flex;
  list-style: none;
}

.nav-item {
  margin-left: 10px;
}

.nav-links {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .menu-icon {
    display: block; /* Show the hamburger icon on smaller screens */
    font-size: 1.5rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100px; /* Adjust the top position of the mobile menu */
    background-color: #333;
    width: 100%;
    opacity: 0;
    transition: all 0.5s ease;
    padding: 10px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: 1;
  }

  .nav-item {
    margin-right: 35px;
    margin-bottom: 15px
  }
}

/* Slideshow Styling */
.slideshow-container {
  display: flex;
  justify-content: center; /* Horizontally center images */
  align-items: center; /* Vertically center images */
  width: 100%; /* Set the width to fill the container */
  max-width: calc(100% - 20px); /* Adjust the max-width as needed */
  margin: 130px auto 20px; /* Adjust the margins accordingly */
  overflow: hidden;
  border: 2px solid #ccc; /* Add a border with your preferred style */
  border-radius: 5px; /* Optionally add border radius */
  padding: 10px; /* Adjust padding as needed */
  box-sizing: border-box;
}

.slide {
  display: none; /* Hide slides by default */
  width: 100%; /* Set slide width */
  height: 300px; /* Adjust slide height as per aspect ratio */
  background-size: cover;
  transition: opacity 1s ease; /* Add transition for smoother image change */
}

.slide.active {
  display: block; /* Display active slide */
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .slideshow-container {
    width: 60%; /* Adjust the width for larger screens */
    margin: 130px auto 20px; /* Adjust the margins accordingly */
  }

  .slide {
    height: 500px; /* Adjust the slide height for larger screens */
  }
}

/* Home Page Component Styling */

.container {
  padding-top: 50px; /* Adjust as needed */
  margin: 0 auto; /* Center the content horizontally */
  max-width: 1200px; /* Adjust as needed */
  padding-left: 10px; /* 10px padding on the left */
  padding-right: 10px; /* 10px padding on the right */
}

.home-container {
  background: linear-gradient(to right, #47a83f, #007acc); 
  
  padding: 20px; /* Add padding as needed */
  color: white;
  /* Example styles for background or border */
  /* background-color: #f5f5f5; */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}


/* About Us Section styles */
.about-section {
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
}

/* Styles for the content wrapper */
.content-wrapper {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center items horizontally */
  max-width: 1000px; /* Adjust the maximum width as needed */
  /* margin: 0px auto; Center the container */
}

/* Styles for the image container */
.image-container {
  flex: 0 0 calc(40% - 10px); /* Adjust the width as needed */
  /*flex: 1;  Take up available space */
  /*margin-right: 10px; /* Adjust margin between image and text */
  /*padding-left: 15px; /* Padding on the left side of the image and screen */
}

/* Styles for the image */
.image-container img {
  max-width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
}


/* Example styles for text content */
.text-container h2 {
  font-size: 24px;
  margin-bottom: 0px;
}

.text-container p {
  font-size: 16px;
  line-height: 1.6;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .container {
    padding-top: 20px; /* Adjust padding for smaller screens */
  }

  .home-container {
    padding: 15px; /* Adjust padding for smaller screens */
    background: linear-gradient(to right, #47a83f, #007acc); 
  }

  .about-section .content-wrapper {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center;
    text-align: center;
  }

  .text-container {
    flex: 1; /* Take up available space */
    padding-left: 0; /* Remove padding on smaller screens */
    padding-top: 10px; /* Add top padding */
  }

  .text-container h2 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  .text-container p {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .services-section .icon-link {
    flex-basis: calc(50% - 20px); /* Two icons per row on smaller screens */
  }
}

/* Services Section styles */
.services-section {
  display: flex;
  /* flex-direction: column;
  align-items: center; Center items horizontally */
  flex-wrap: wrap;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure each row spans the full width */
  margin-bottom: 20px;
}

.row:last-of-type {
  justify-content: center; /* Center items in the last row */
}

.row:last-of-type .icon-link {
  flex: 0 1 30%; /* Same size as other icons, but only take up needed space */
}

.icon-container {
  flex: 0 0 calc(33.33% - 20px); /* Adjust width and add margin */
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.icon-container:hover {
  background-color: #ddd; /* Change background color on hover */
}

.icon-container i {
  font-size: 36px;
  margin-bottom: 10px;
}

.icon-container p {
  font-size: 16px;
  color: black;
}

/* Stlying for the link for services */
.icon-link {
  display: block; /* Make the link behave like a block-level element */
  width: 100%; /* Take up the entire width of its parent container */
  height: 100%; /* Take up the entire height of its parent container */
  text-decoration: none; /* Remove default underline */
  color: black; /* Inherit the color from the parent */
}

.icon-link:hover {
  color: #007bff; /* Change color on hover */
}

.services-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.intro-box {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.image-grid img {
  width: 100%;
  height: 200px;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.center-content-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}
.content-box {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
}

.content-box h3 {
  margin-bottom: 10px;
}

.content-box ul {
  list-style: none;
  padding: 0;
}

.centered-content-box {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.view-more-button-container {
  margin-top: 20px;
  text-align: center;
}

.view-more-button {
  padding: 10px 20px;
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.view-more-button:hover {
  background-color: #0056b3; /* Different color on hover */
}

@media (max-width: 768px) {
  .row {
  /* Stack items vertically on smaller screens */
    align-items: center; /* Center items vertically */
    padding-left: 7px;
  }

  /* Responsive adjustments */
@media (max-width: 768px) {
  .icon-link {
    flex: 1 1 calc(50% - 1em); /* 2 items per row */
  }
}

@media (max-width: 480px) {
  .icon-link {
    flex: 1 1 calc(100% - 1em); /* 1 item per row */
  }
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 10px;
  height: 130px;
  width: 90px;
}


  .icon-link {
    width: 100%; /* Full width for link */
    height: auto; /* Auto height */
  }

  .icon-container i {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .icon-container p {
    margin: 0;
    font-size: 1em;
  }

  .services-container {
    padding: 10px; /* Adjust padding */
  }

  .intro-box,
  .content-box,
  .centered-content-box {
    padding-left: 10px; /* Adjust padding */
   
  }

  .image-grid img {
    height: 150px; /* Adjust image height */
  }

  .content-box h3 {
    font-size: 15px;
  }
  
  .content-box li {
    font-size: 12px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 10px;
  }

  .center-content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }

  .view-more-button {
    padding: 8px 16px; /* Adjust button padding */
  }
}


/* Contact Section Styles */
.contact-section {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-container h1 {
  margin-left: 30px;
  font-size: 35px;
}

.contact-container {
  margin-top: 30px;
}

.contact-section h2 {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  margin-bottom: 20px;
}

.contact-section h2:hover {
  color: #0056b3;
}
.contact-info {
  margin-bottom: 20px;
  color: black;
}

.contact-info p {
 
  margin-bottom: 10px;
}

.social-media-icons a {
  margin-right: 10px;
}

.social-media-icons i {
  font-size: 24px;
  color: #333;
}

.status-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.error-message {
  color: #dc3545;
  font-size: 0.9em;
  margin-top: 5px;
}

.form {
  max-width: 600px !important;
  margin: 50px auto !important ;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .contact-section {
    padding: 15px;
   
  }

  .contact-container {
    margin-top: 40px;
  }

  .contact-container h2 {
    margin-left: 20px;
  }

  .social-media-icons a {
    margin-right: 8px; /* Reduce margin between social icons */
  }

  .status-message {
    padding: 8px; /* Adjust padding for smaller devices */
  }

  .form {
    max-width: 60% !important;
    margin: 20px auto !important; /* Center the form with reduced margin */
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
    font-size: 14px; /* Adjust font size for readability */
  }

  button[type="submit"] {
    font-size: 14px; /* Adjust button font size */
  }
}



/* Footer Section styles */
.footer {
  text-align: center;
  margin-top: 50px;
  padding: 20px 0;
  color: black;
}

/* Services Page Styling */
.services-container {
  background: linear-gradient(to right, #47a83f, #007acc);
  padding-top: 140px; /* Adjust as needed */
  margin: 0 auto; /* Center the content horizontally */
  max-width: 1200px; /* Adjust as needed */
  padding-left: 10px; /* 10px padding on the left */
  padding-right: 10px; /* 10px padding on the right */
}

.statement-box {
  display: flex;
  margin-bottom: 10px;
}

.image-container {
  height: 140px; /* Adjust the height as needed */
  margin-right: 20px;
}

.image-container img {
  width: 75%;
  height: 120%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 8px;
}

.service-content {
  padding-top: 60px;
}



/* Contact Page Styling */


.contact-container {
  background: linear-gradient(to right, #47a83f, #007acc);
  padding: 20px;
  border-radius: 8px;
}

.contact-form {
  flex: 1;
  margin-right: 20px;
  margin-top: 110px;
}

.contact-form form {
  padding: 20px;
  margin-left: 265px;
  width: 100%;
  max-width: 500px; 
}

.contact-form form .form-group {
  margin-bottom: 20px;
  margin-left: 20px;
}

.contact-form form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form form input[type="text"],
.contact-form form input[type="email"],
.contact-form form input[type="tel"],
.contact-form form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form form textarea {
  resize: vertical;
}

.contact-form form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 30px; 
}

.contact-form form button:hover {
  background-color: #0056b3;
}

.contact-info h2 {
  margin-bottom: 10px;
}

.contact-info p {
  margin-bottom: 5px;
}

.social-media-icons a {
  display: inline-block;
  margin-right: 10px;
  color: #007bff;
  font-size: 20px;
}

.social-media-icons a:hover {
  color: #0056b3;
}

.map-container  {
  margin: 20px 0 !important;
  width: 100% !important;
  height: 450px !important;
}